@import '~antd/dist/antd.less';

body {
  background: #f0f2f5;
}

.fade-enter,
.fade-appear {
  opacity: 0;
  transform: translateX(-10px);
}

.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.5s;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0px);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateX(30px);
  transition: all 0.5s;
}

@primary-color: #40a9ff;